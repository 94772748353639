.authorization-modal {
  &__btns {
    margin-top: 20px;
  }
  &__btn {
    width: 100%;
  }
  &__forget {
    display: block;
    color: $b_indigo;
    margin-top: 35px;
    margin-bottom: 40px;
  }
  &__activation-info {
    margin-top: 45px;
    margin-bottom: 40px;
  }
  &__form {
    &-btn {
      width: 100%;
    }
  }
  &__input {
    &.success {
      border-color: $g_green;
    }
  }
  &__label {
    &.success {
      color: $g_green;
    }
  }
}

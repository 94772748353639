* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#root {
  font-family: "Commons", sans-serif;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
  color: $n_dark;
  @include max($t) {
    font-size: 17px;
    line-height: 20px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Travels", sans-serif;
  font-style: normal;
  color: $n_darkest;
  &.w {
    color: $white;
  }
}

.h1,
.h2,
h1,
h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.h3,
h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;

  @include max($s) {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin h3-m {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
@mixin h4-m {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
@mixin p-m {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.h4,
h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  &.h4-m {
    @include max($s) {
      font-weight: 500;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}

.h5,
h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
}

p,
.p {
  font-family: "Commons", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  @include max($s) {
    @include p-m;
  }

  &.sm {
    font-weight: 500;
    font-size: 15px;
    line-height: 19.6px;
  }

  &.fw-400 {
    font-weight: 400;
  }

  &.sm-m {
    @include max($s) {
      font-weight: 500;
      font-size: 15px;
      line-height: 19.6px;
    }
  }

  &.lh-0 {
    line-height: initial;
  }

  &.s-17 {
    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
  }
}

.label {
  font-family: "Travels", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-weight: 16.8px;
}
i {
  &::before {
    // vertical-align: middle;
  }
}
a,
.a {
  color: $b_sky;
  transition: all 0.2s;
  &:hover {
    text-decoration: underline;
    color: $b_indigo;
    @include max($s) {
      text-decoration: none;
    }
    i {
      text-decoration: none;
      text-decoration: dotted;
      &::before {
        text-decoration: none;
      }
    }
  }

  &.sm {
    font-weight: 500;
    font-size: 15px;
    line-height: 19.6px;
  }

  &.fw-400 {
    font-weight: 400;
  }

  &.white {
    &:hover {
      color: white;
    }
  }

  &.icon {
    &:hover {
      text-decoration: none;
      .text {
        text-decoration: underline;
      }
    }
  }
}
.medium {
  font-weight: 500;
}

.c-gray {
  color: #717188;
}
.c-blue {
  color: #096ade;
}
.c-white {
  color: #fff;
}

.root {
  min-height: 100vh;
}

.a-with-i {
  &:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

.rich-text {
  h1,
  .h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    margin-bottom: 20px;

    @include max($s) {
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;

      margin-bottom: 10px;
    }
  }

  h2,
  .h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;

    margin-bottom: 20px;

    @include max($s) {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;

      margin-bottom: 8px;
    }
  }

  h3,
  .h3 {
    font-family: "Commons";
    font-size: 18px;
    line-height: 20.7px;
    font-weight: 600;
    margin-bottom: 0px;

    @include max($s) {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;

      margin-bottom: 0px;
    }
  }

  p,
  .p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    margin-bottom: 15px;
    @include max($s) {
      //       font-weight: 400;
      // font-size: 17px;
      // line-height: 20px;
    }
  }

  a,
  .a {
    color: $b_indigo;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;

    margin-bottom: 15px;

    li {
      margin-bottom: 10px;

      @include max($s) {
        margin-bottom: 0px;
      }
    }
  }

  ul {
    list-style: disc none;
    margin-left: 15px;
    li {
      font-family: "Commons";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      margin-bottom: 20px;

      @include max($s) {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}

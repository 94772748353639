.bonus-pay {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px !important;
  @include max($t) {
    margin-bottom: 0px !important;
  }
  &__button {
    margin-top: 20px;
    @include max($t) {
      width: 100%;
    }
  }
  &__volue {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 18px;
      line-height: 20.7px;
    }
    input {
      border-radius: 2px;
      border: 1px solid $b_light;
      box-sizing: border-box;
      padding: 17.5px 46px 11.5px 20px;
      width: 120px;
      text-align: right;
      position: relative;
      &::before {
        font-family: "icons" !important;
        content: "\e901";
        position: absolute;
        right: 16.5px;
        top: 17.5px;
      }
    }
  }
  &__total,
  &__available {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__total {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    margin-top: 5px;

    @include max($t) {
      order: 2;
      margin-top: 20px;
    }

    button {
      font-family: "Travels";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;

      color: $b_indigo;

      padding: 12px 20px;

      background: #f4f9ff;
      border-radius: 2px;

      margin-right: 10px;
    }
  }

  &__input {
    @include min($t) {
      display: none;
    }
    input {
      width: 100%;
      padding: 12.5px 0 6.5px;
      text-align: center;

      margin-top: 40px;

      border: 1px solid #e8f1fd;
      border-radius: 2px;
    }
  }

  &__descr {
    .i-bonuses {
      color: $b_indigo;
    }

    &_count {
      @include max($t) {
        display: none;
      }
    }
  }

  // input[type="range"] {
  //   -webkit-appearance: none;
  //   // margin-right: 15px;
  //   // width: 200px;
  //   // height: 7px;
  //   // background: rgba(255, 255, 255, 0.6);
  //   // border-radius: 5px;
  //   // background-image: linear-gradient(#ff4500, #ff4500);
  //   // background-size: 70% 100%;
  //   // background-repeat: no-repeat;

  //   &::-webkit-slider-runnable-track {
  //     -webkit-appearance: none;
  //     // box-shadow: none;
  //     // border: none;
  //     // background: transparent;
  //   }

  //   &::-webkit-slider-thumb {
  //     -webkit-appearance: none;
  //     // height: 20px;
  //     // width: 20px;
  //     // border-radius: 50%;
  //     // background: #ff4500;
  //     // cursor: ew-resize;
  //     // box-shadow: 0 0 2px 0 #555;
  //     // transition: background 0.3s ease-in-out;
  //   }
  // }

  .rc-slider-mark-text {
    strong {
      font-family: "Commons";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      color: $n_darkest;

      label {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;

        color: $n_grey;
      }
    }
  }

  .slider-cont {
    display: flex;

    margin-top: 50px;
    position: relative;
    width: 100%;
    padding-left: 3px;

    &__top {
      position: absolute;
      top: -28px;

      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      &_start {
        left: 0px;
        @include max($t) {
          display: none;
        }
      }

      &_max {
        right: 0px;
        @include max($t) {
          display: none;
        }
      }

      &_max-choose {
        color: $n_grey;
      }
    }

    .multi-slider-cont {
      position: relative;
      margin-right: 6px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;

      &:first-of-type {
        .multi-slider {
          border-radius: 5px 0px 0px 5px;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      &_last {
        .multi-slider {
          border-radius: 0px 5px 5px 0px;
        }
      }
      .multi-slider {
        width: 100%;
        height: 6px;

        background-color: $b_light;

        &_choose {
          top: 0px;
          left: 0px;
          position: absolute;
          width: 0px;

          background-color: $b_sky;
        }

        &_block {
          top: 0px;
          right: 0px;
          width: 0px;
          position: absolute;

          background-color: #9e9ead;
        }

        &__desc {
          margin-left: auto;
          margin-right: auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          margin-top: 15px;

          @include max($t) {
            margin-left: 0px;
            align-items: flex-start;
          }
        }

        &__value {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
        }

        &__date {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;

          color: $n_grey;
        }
      }
    }

    .mark {
      position: absolute;

      cursor: grab;

      top: -3px;

      width: 12px;
      height: 12px;

      border-radius: 50%;
      background-color: $b_sky;
    }
  }
}

@import "./SertBlock/sertBlock.scss";
@import "./ReviewBlock/review-block.scss";

.prod-descr {
  color: $n_dark;
  position: relative;
  &__main {
    position: relative;
    padding-right: 40px;
    @include max($s) {
      padding-right: 0px;
      margin-bottom: 50px;
    }
    .prod-descr__open-zoom {
      position: absolute;
      right: 60px;
      top: 20px;
      z-index: 3;
      i {
        font-size: 24px;
        color: #70a4ef;
      }
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
    }
  }
  &__main-slider {
    .slick-list {
      width: 100%;
    }
  }
  &__main-thumb {
    .slick-list {
      width: 100%;
    }
    .slick-track {
      margin-left: 0;
    }
    .slick-slide {
      transition: all 0.2s;
      opacity: 0.5;
      cursor: pointer;
    }
    .slick-current {
      opacity: 1;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
  &__content {
    margin-bottom: 100px;
    @include max($s) {
      margin-bottom: 0px;
    }
  }
  &__head {
    margin-bottom: 150px;
    &-img {
      width: 100%;
    }

    &.separ {
      margin-bottom: 100px;
      @include max($s) {
        margin-bottom: 0px;
      }
      .prod-descr__main {
        display: flex;
        width: 100%;

        .slick-track {
          // width: 100% !important;

          .slick-slide {
            // width: 100% !important;
          }
        }
        .prod-descr__main-thumb {
          width: 50px;
          max-height: 600px;
          order: 1;
          overflow: hidden;
          position: absolute;
          .slick-list {
            padding-bottom: 20px;
          }

          .slick-arrow {
            z-index: 20;
            position: absolute;
            bottom: 0px;
            top: auto;
            right: 0%;
            transform: rotate(90deg);

            &.slick-next {
              right: 50%;
            }
          }
        }

        .prod-descr__main-slider {
          width: 100%;
          order: 2;
          padding-left: 55px;

          .slick-list {
            border-right: #e8f1fd 1px solid;
          }

          @include max($s) {
            padding-left: 0px;
            .slick-list {
              border-right: none;
            }
          }
        }

        .i-plus {
          display: none;
        }
      }
      .prod-descr__price-left {
        border: none;
        .prod-descr__price-group {
          .prod-descr__price-new {
            font-size: 18px;
          }
        }
      }

      .prod-list__har {
        display: block;
      }
    }

    .prod-list__har {
      display: none;
      padding: 15px 0px;
      border-top: #e8f1fd 1px solid;
      border-bottom: #e8f1fd 1px solid;
      margin-bottom: 50px;
      max-width: 100%;
      max-height: 100%;
    }
    @include max($s) {
      margin-bottom: 0px;
      // p {
      //   font-size: 14px;
      //   line-height: 20px;
      // }

      ul {
        li {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  &__title {
    margin-bottom: 20px;
    color: $n_darkest;
  }
  &__code {
    color: $n_grey;
    margin-bottom: 50px;
    font-size: 15px;
    @include max($s) {
      margin-bottom: 20px;

      @include p-m;
    }
  }
  &__color {
    margin-bottom: 25px;
    &-title {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 20px;
    }
    &-value {
      font-size: 17px;
      margin-left: 10px;
    }
    img {
      border-radius: 2px;
      border: 1px solid transparent;
      transition: all 0.2s;
    }
    .slick-slide:hover {
      img {
        border: 1px solid $b_sky;
      }
    }
    .active {
      img {
        border: 1px solid $b_indigo;
      }
    }
  }
  &__size {
    margin-bottom: 10px;
    z-index: 3;
    @include max($s) {
      margin-bottom: 20px;
    }
    &-head {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      @include max($s) {
        margin-bottom: 20px;
      }
    }
    .drop {
      width: 100%;
    }
    &-title {
      font-weight: 500;
      @include max($s) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    &-table {
      margin-left: auto;
      color: $b_sky;
    }
    &-select {
      width: 100%;
    }
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @include max($s) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0px;
    }
    &-bonus {
      display: flex;
      align-items: center;
      text-align: center;
      & > i {
        color: $b_indigo;
        margin-right: 10px;
        font-size: 20px;
        display: flex;

        ::before {
          align-items: center;
        }
      }
    }
    &-group {
      display: flex;
      align-items: center;
      font-family: "Travels", sans-serif;
    }
    &-left {
      margin-bottom: 10px;
      @include max($s) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 25px;
        border-bottom: 1px solid $b_light;
      }
    }
    &-cheap {
      display: flex;
      line-height: 20px;
      width: 100%;
      @include max($s) {
        width: auto;
      }
      & button {
        display: flex;

        align-items: flex-end;
      }
      & span {
        padding: 0;
        margin: 0;

        line-height: 16px;
      }
      & i {
        font-size: 20px;
        margin-right: 10px;
        @include max($s) {
          display: none;
        }
      }
    }
    &-old {
      font-family: "Travels", sans-serif;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      text-decoration: line-through;
      margin-right: 15px;
    }
    &-new {
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 600;
      color: $r_red;
    }
    &-bonus {
      padding: {
        top: 10px;
        bottom: 10px;
        left: 12px;
        right: 13px;
      }
      background-color: $b_light;
      border-radius: 26px;
      font-size: 15px;
      line-height: 13px;
    }
  }
  &__cart {
    margin-bottom: 43px;
    .prod-descr__cart-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include max($s) {
        flex-direction: column;
        align-items: flex-end;
        // margin-top: -108px;
        // margin-bottom: 33px;
      }
    }
    .prod-descr__date {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e8f1fd;
      & > i {
        display: flex;
      }
      & > div {
        display: flex;
        justify-content: end;
        flex-direction: column;
        p {
          font-weight: normal;
          font-size: 17px;
          line-height: 19px;

          &.c-gray {
            color: $n_grey;
          }
        }
      }

      @include max($s) {
        border-top: none;
        padding-top: 0px;
        margin-top: 30px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  &__date {
    font-size: 17px;
    margin-bottom: 26px;
    @include max($s) {
      margin-bottom: 16px;
    }
    & i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  &__choose {
    position: relative;
    & i {
      margin-right: 10px;
    }
    &-item {
      margin-bottom: 26px;
    }
    &-title {
      color: $b_sky;
      font-size: 17px;
    }
    &-dropdown {
      padding: 20px;
      font-weight: 500;
      background: #fff;
      box-shadow: 0px 2px 10px rgba(2, 8, 30, 0.1);
      border-radius: 2px;
      top: 30px;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__advice {
    display: flex;

    justify-content: center;
    align-items: center;

    padding: 10px 20px;
    margin-bottom: 40px;

    background-color: #e8f1fd;
    border-radius: 6px;

    color: $b_indigo;

    font-family: "Travels";
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;

    @include max($s) {
      width: 100%;
    }

    i {
      vertical-align: middle;

      font-size: 20px;

      margin-right: 10px;
    }
  }

  &__charact {
    @include max($s) {
      &:last-child {
        margin-bottom: 50px;
      }
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid $b_light;
      margin-bottom: 20px;
    }
    &.active {
      .prod-descr__charact-dropdown {
        max-height: 500px;
        visibility: visible;
        opacity: 1;
      }
      .prod-descr__charact-head {
        i {
          transform: rotate(180deg);
        }
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Travels", sans-serif;
      cursor: pointer;

      i {
        transition: all 0.2s linear;
      }
    }
    &-title {
      color: $n_darkest;

      @include max($s) {
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
      }
    }
    &-dropdown {
      visibility: hidden;
      max-height: 0px;
      opacity: 0;
      padding-top: 20px;
      overflow: auto;
      font-size: 18px !important;
      line-height: 25px !important;

      transition: all 0.2s linear;

      @include max($s) {
        p {
          font-size: 17px !important;
          line-height: 20px !important;
        }
        td {
          font-size: 15px !important;
          line-height: 20px !important;
        }
      }
    }
  }
  &__help {
    &-item {
      font-family: "Travels", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 30.8px;
      color: $b_sky;
      padding-bottom: 20px;
      border-bottom: 1px solid $b_light;
      margin-bottom: 20px;

      & i {
        margin-right: 10px;
        font-size: 24px;
        &:before {
          vertical-align: sub;
        }
      }
    }
  }
  &__brand {
    font-size: 18px;
    line-height: 25.2px;
    display: flex;
    flex-direction: column;

    &-info {
      color: #222736;
      margin-bottom: 10px;
    }
    &-link {
      color: $b_sky;
    }
    &-descr {
      @include max($s) {
        // display: none;
        order: 1;
        margin-bottom: 50px;
      }
      // @include max($m) {
      //   display: none;
      // }
    }
    &-logo {
      img {
        width: 100%;
      }
      @include max($s) {
        margin-bottom: 20px;
        height: 38px;
        img {
          width: auto;
          height: 100%;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 10px;
    }
  }

  .slick-df {
    .slick-next,
    .slick-prev {
      right: auto;
      top: 6px;
      margin-left: 20px;

      @include max($m) {
        position: absolute;
        top: -45px;
        right: 10px;
      }
    }
    .slick-next {
      order: 2;
    }

    .slick-prev {
      order: 1;
      @include max($m) {
        right: 50px;
      }
    }
  }

  .slick-arrow {
    @include max($s) {
      display: none !important;
    }
  }

  &__zoom-image {
    z-index: 99999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    padding: 0px 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .prod-descr__close-zoom {
      position: absolute;
      right: 100px;
      top: 30px;
      font-size: 24px;
    }

    .slick-dots {
      bottom: -5px;
    }

    .prod-descr__main-slider {
      height: 100%;
      width: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .slick-list {
        .slick-track {
          margin: auto !important;
          // height: 100%;
          // .slick-slide {
          //   height: 100%;
          //   div {
          //     height: 100%;
          //     width: auto !important;
          //     .prod-descr__head-img {
          //       height: 100%;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.prod-descr__bonus-count {
  @include max($s) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 25px;
    .input--amount {
      margin-bottom: 0px;
    }
  }
}

.sert-block-choose-sum {
  display: flex;
  flex-wrap: wrap;
  @include max($s) {
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 10px;
    // padding-left: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  a {
    padding: 12px 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    @include max($s) {
      white-space: nowrap;
      vertical-align: middle;
      text-align: center;

      margin-bottom: 0px;
      padding: 10px 20px 6px;

      font-family: "Commons";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.sert-block-input-sum {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @include max($s) {
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__input-block {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 50%;
    position: relative;
    @include max($s) {
      order: 1;
      width: 100%;
      margin-right: 10px;
    }
  }

  &__input {
    position: relative;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid $b_light;
    border-radius: 2px;
    text-align: center;
    vertical-align: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;

    &:focus {
      border-color: $b_indigo;
    }

    &:before {
      content: "₽";
      position: absolute;
      display: block;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: black;
    }
  }

  &__rub {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
  }

  &__text {
    font-family: "Commons", sans-serif;
    font-size: 15px;
    line-height: 19.6px;
    font-weight: 500;
    color: $n_grey;

    @include max($s) {
      margin-bottom: 15px;
      order: 0;
    }
  }
}
